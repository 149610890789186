<template>
  <b-modal
    v-model="_state"
    size="lg"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    @show="initForm"
  >
    <template v-slot:modal-title>
      <h5 class="mb-0 text-secondary">
        {{ $t("user") }} -
        <b-badge
          class="text-white font-weight-normal"
          :variant="userStatusVariant[user.status]"
        >
          {{ $t(user.status) }}
        </b-badge>
      </h5>
    </template>
    <template v-slot:default>
      <validation-observer ref="observer" v-slot="{ invalid }">
        <b-form>
          <b-row class="no-gutters ml-n3">
            <b-col class="col-12 pl-3">
              <form-input
                v-model="user.email"
                label="requiredField.email"
                :disabled="true"
              />
            </b-col>
            <b-col class="col-12 col-md-6 pl-3">
              <form-input
                v-model="user.name"
                label="requiredField.name"
                veeName="name"
                :veeRules="{ required: true }"
              />
            </b-col>
            <b-col class="col-12 col-md-6 pl-3">
              <form-input
                v-model="user.surname"
                label="requiredField.surname"
                veeName="surname"
                :veeRules="{ required: true }"
              />
            </b-col>
            <div class="w-100 ml-3 my-4" />
            <b-col class="col-12 pl-3">
              <form-input
                v-model="user.legalName"
                label="requiredField.legalName"
                veeName="legalName"
                :veeRules="{ required: true }"
              />
            </b-col>
            <b-col class="col-12 col-md-6 pl-3">
              <form-input
                v-model="user.mobile"
                label="requiredField.mobile"
                veeName="mobile"
                :veeRules="{ required: true }"
              />
            </b-col>
            <b-col class="col-12 col-md-6 pl-3">
              <form-input v-model="user.landline" label="landline" />
            </b-col>
            <b-col class="col-12 col-md-6 pl-3">
              <form-input
                v-model="user.vat"
                label="requiredField.vat"
                veeName="vat"
                :veeRules="{ required: true }"
              />
            </b-col>
            <b-col class="col-12 col-md-6 pl-3">
              <form-input
                v-model="user.companyEmail"
                label="companyEmail"
                veeName="companyEmail"
                :veeRules="{ email: true }"
                @input="user.companyEmail = user.companyEmail.toLowerCase()"
              />
            </b-col>
            <b-col class="col-12 pl-3" :class="{ 'col-sm-9': hasAddressInfo }">
              <google-place-search
                v-model="searchAddress"
                label="requiredField.address"
                veeName="address"
                :address="user.address"
                :veeRules="{ required: true }"
                @setPlaceInfo="setPlaceInfo"
                @reset="resetAddressDetails"
              />
            </b-col>

            <b-col v-if="hasAddressInfo" class="col-12 col-sm-3 pl-3">
              <form-input
                v-model="user.streetNumber"
                label="requiredField.streetNumber"
                veeName="streetNumber"
                :veeRules="{ required: true }"
              />
            </b-col>
            <b-row v-if="hasAddressInfo" class="no-gutters ml-m3 w-100">
              <b-col class="col-12 col-sm-3 pl-3">
                <form-input
                  v-model="user.zip"
                  label="requiredField.cap"
                  veeName="cap"
                  :veeRules="{ required: true }"
                />
              </b-col>
              <b-col class="col-12 col-sm-6 pl-3">
                <form-input
                  v-model="user.city"
                  label="requiredField.city"
                  veeName="city"
                  :veeRules="{ required: true }"
                />
              </b-col>
              <b-col class="col-12 col-sm-3 pl-3">
                <form-input
                  v-model="user.province"
                  label="requiredField.province"
                  veeName="province"
                  :veeRules="{ required: true }"
                />
              </b-col>
            </b-row>
            <div class="w-100 ml-3 my-4" />
            <b-col class="col-12 col-md-6 pl-3">
              <form-input
                v-model="user.fiscalCode"
                label="requiredField.fiscalCode"
                veeName="fiscalCode"
                :veeRules="{ required: true }"
              />
            </b-col>
            <b-col class="col-12 col-md-6 pl-3">
              <form-input v-model="user.website" label="website" />
            </b-col>
            <b-col class="col-12 col-md-6 pl-3">
              <form-input v-model="user.legalEmail" label="legalEmail" />
            </b-col>
            <b-col class="col-12 col-md-6 pl-3">
              <form-input v-model="user.fax" label="fax" />
            </b-col>
          </b-row>

          <hr class="my-3 mx-n3" />
          <b-row class="no-gutters justify-content-end">
            <b-button
              variant="outline-dark"
              class="mr-1"
              @click="setModalState(false)"
            >
              {{ $t("button.cancel") }}
            </b-button>
            <b-button
              class="text-white"
              variant="primary"
              :disabled="invalid"
              @click="updateAdmin"
            >
              {{ $t("button.saveChanges") }}
            </b-button>
          </b-row>
        </b-form>
      </validation-observer>
    </template>
  </b-modal>
</template>

<script>
import FormInput from "../Base/BaseFormInput";
import GooglePlaceSearch from "./../Google/GooglePlaceSearch";
export default {
  components: {
    FormInput,
    GooglePlaceSearch
  },
  props: {
    state: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      user: {},
      searchAddress: "",
      userStatusVariant: {
        active: "green",
        notverified: "cyan"
      }
    };
  },
  computed: {
    _state: {
      get() {
        return this.state;
      },
      set(value) {
        this.setModalState(value);
      }
    },
    hasAddressInfo() {
      return this.user.address.length > 0;
    }
  },
  methods: {
    initForm() {
      const curUser = this.$store.getters["admin/getCurrentAdminUser"];
      this.user = {
        _id: curUser._id,
        status: this.mapStatus(curUser.status),
        administratorId: curUser.profile.administratorId,
        name: curUser.profile.name || "",
        surname: curUser.profile.surname || "",
        email: curUser.email || "",
        mobile: curUser.generalInfo.mobile || "",
        landline: curUser.generalInfo.landline || "",
        companyEmail: curUser.generalInfo.email || "",
        legalName: curUser.generalInfo.name || "",
        vat: curUser.generalInfo.vat || "",
        address: curUser.generalInfo.address || "",
        zip: curUser.generalInfo.zip || "",
        province: curUser.generalInfo.province || "",
        city: curUser.generalInfo.city || "",
        streetNumber: curUser.generalInfo.streetNumber || "",
        country: curUser.generalInfo.country || "",
        latitude: curUser.generalInfo.latitude || "",
        longitude: curUser.generalInfo.longitude || "",
        placeId: curUser.generalInfo.placeId || "",
        fiscalCode: curUser.generalInfo.fiscalCode || "",
        website: curUser.generalInfo.website || "",
        legalEmail: curUser.generalInfo.legalEmail || "",
        fax: curUser.generalInfo.fax || ""
      };

      this.searchAddress = this.user.address;
    },
    setModalState(value) {
      this.$emit("update:state", value);
    },
    resetAddressDetails() {
      this.user.address = "";
      this.user.zip = "";
      this.user.province = "";
      this.user.city = "";
      this.user.streetNumber = "";
      this.user.country = "";
      this.user.latitude = 0;
      this.user.longitude = 0;
      this.user.placeId = "";
    },
    setPlaceInfo(place) {
      this.user.address = place.streetName;
      this.searchAddress = this.user.address;

      this.user.zip = place.postalCode;
      this.user.province = place.province;
      this.user.city = place.city;
      this.user.streetNumber = place.streetNumber;
      this.user.country = place.country;
      this.user.latitude = parseFloat(place.lat);
      this.user.longitude = parseFloat(place.lng);
      this.user.placeId = place.placeId;
    },
    async updateAdmin() {
      try {
        this.$store.commit("loader/SET_LOADER", {});

        await this.$store.dispatch("user/updateUser", {
          userId: this.user._id,
          userInfo: this.getUserPayload()
        });

        await this.$store.dispatch("admin/updateAdministrator", {
          administratorId: this.user.administratorId,
          adminInfo: this.getAdminPayload()
        });

        await this.$store.dispatch("admin/retrieveAdminUsers");

        this.setModalState(false);
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    getUserPayload() {
      return {
        name: this.user.name,
        surname: this.user.surname
      };
    },
    getAdminPayload() {
      return {
        name: this.user.legalName,
        mobile: this.user.mobile,
        landline: this.user.landline,
        email: this.user.companyEmail,
        address: this.user.address,
        zip: this.user.zip,
        province: this.user.province,
        city: this.user.city,
        streetNumber: this.user.streetNumber,
        country: this.user.country,
        latitude: this.user.latitude,
        longitude: this.user.longitude,
        placeId: this.user.placeId,
        vat: this.user.vat,
        website: this.user.website,
        fiscalCode: this.user.fiscalCode,
        legalEmail: this.user.legalEmail,
        fax: this.user.fax
      };
    },
    mapStatus(status) {
      if (status === "notactivated") return "notverified";
      else return status;
    }
  }
};
</script>

<style lang="scss" scoped></style>
